<template>
  <div>
    <cabecContrato
      :contrato="contrato"
      :cliente="cliente"
      :documentos-contrato="documentosContrato"
      :series-contrato="seriesContrato"
      :serie-selecionada="serieSelecionada"
    />
    <b-card>

      <b-tabs
        pills
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Contrato</span>
          </template>
          <dadosContrato
            :contrato="contrato"
            :estados="estados"
            :estado-selecionado="estadoSelecionado"
            :vendedor="vendedor"
            :moeda="moeda"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Valor</span>
          </template>
          <valorContrato
            :contrato="contrato"
          />
        </b-tab>

        <b-tabs pills />
      </b-tabs>
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="guardarContrato()"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BTab, BTabs, BRow, BCol,
} from 'bootstrap-vue'

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import cabecContrato from './Adicionar/CabecContrato.vue'
import dadosContrato from './Adicionar/DadosContrato.vue'
import valorContrato from './Adicionar/ValorContrato.vue'

export default {

  components: {
    BCard,
    BButton,
    cabecContrato,
    dadosContrato,
    valorContrato,
    BTab,
    BTabs,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userSigned: JSON.parse(JSON.stringify(JSON.parse(localStorage.getItem('userSigned')))),
      contrato: {
        id: '',
        tipoDoc: '',
        serie: '',
        numero: 0,
        codigo: '',
        data: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        entidade: '',
        referencia: '',
        estado: '',
        cancelado: false,
        canceladoMotivo: '',
        renovavel: false,
        moeda: '',
        valorContrato: 0,
        utilizador: '',
        vendedor: '',
        precoBase: 0,
        dataAbertura: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 19),
        descricao: '',
        dataAdjudicacao: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        periodoValidade: false,
        periodoInicio: null,
        periodoFim: null,
        horasValidade: false,
        horasTotais: '0.00',
        horasGastas: '0.00',
        incidenciasValidade: false,
        incidenciasTotais: 0,
        incidenciasGastas: 0,
        excPeriodoValidade: false,
        excPeriodoInicio: null,
        excPeriodoFim: null,
        excHorasValidade: false,
        excHorasTotais: '0.00',
        excHorasGastas: '0.00',
        excIncidenciasValidade: false,
        excIncidenciasTotais: 0,
        excIncidenciasGastas: 0,
        processaFaturacao: 0,
      },
      cliente: {
        id: '',
        cliente: '',
        nome: '',
        nomeFiscal: '',
        morada: '',
        localidade: '',
        codigoPostal: '',
        nif: '',
        idPais: '',
        idMoeda: '',
        idZona: '',
        idVendedor: '',
        telefone: '',
        telemovel: '',
        email: '',
        emailServicos: '',
        ativo: '',
        bloqueado: '',
        observacoes: '',
      },
      seriesContrato: { series: [] },
      estados: { estado: [] },
      estadoSelecionado: { estado: '', key: '' },
      documentosContrato: {
        codigo: '',
        descricao: '',
        inactivo: 0,
      },
      serieSelecionada: { serie: '', key: '' },
      vendedor: {
        id: '',
        codigo: '',
        nome: '',
        email: '',
        telemovel: '',
      },
      moeda: {
        id: '',
        codigo: '',
        descricao: '',
      },
    }
  },

  methods: {

    guardarContrato() {
      this.contrato.serie = this.serieSelecionada.serie.id
      this.contrato.utilizador = this.userSigned.id
      this.contrato.estado = this.estadoSelecionado.estado.id
      this.contrato.referencia = `${this.documentosContrato.codigo}/${this.serieSelecionada.serie.serie}/${this.contrato.numero}`
      if (this.contrato.horasValidade === true) {
        this.contrato.horasValidade = '1'
      } else if (this.contrato.horasValidade === false) {
        this.contrato.horasValidade = '0'
        this.contrato.horasTotais = 0
        this.contrato.horasGastas = 0
      }
      if (this.periodoValidade === true) {
        this.periodoValidade = '1'
      } else if (this.periodoValidade === false) {
        this.contrato.periodoValidade = '0'
        this.contrato.periodoInicio = null
        this.contrato.periodoFim = null
      }
      if (this.contrato.incidenciasValidade === true) {
        this.contrato.incidenciasValidade = '1'
      } else if (this.contrato.incidenciasValidade === false) {
        this.contrato.incidenciasValidade = '0'
        this.contrato.incidenciasTotais = 0
        this.contrato.incidenciasGastas = 0
      }

      if (this.contrato.excHorasValidade === true) {
        this.contrato.excHorasValidade = '1'
      } else if (this.contrato.excHorasValidade === false) {
        this.contrato.excHorasValidade = '0'
        this.contrato.excHorasTotais = '0.00'
        this.contrato.excHorasGastas = '0.00'
      }
      if (this.contrato.excPeriodoValidade === true) {
        this.contrato.excPeriodoValidade = '1'
      } else if (this.contrato.excPeriodoValidade === false) {
        this.contrato.excPeriodoValidade = '0'
        this.contrato.excPeriodoInicio = null
        this.contrato.excPeriodoFim = null
      }
      if (this.contrato.excIncidenciasValidade === true) {
        this.contrato.excIncidenciasValidade = '1'
      } else if (this.contrato.excIncidenciasValidade === false) {
        this.contrato.excIncidenciasValidade = '0'
        this.contrato.excIncidenciasTotais = 0
        this.contrato.excIncidenciasGastas = 0
      }
      axios.post('/api/v1/contratos', this.contrato)
        .then(this.showToast('success', 'CheckIcon'))
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>

<style>

</style>
