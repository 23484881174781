<template>
  <b-card>
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <b-col
            md="10"
            lg="10"
          >
            <b-row>
              <b-col
                md="3"
                lg="3"
              >
                <b-form-group
                  label="Data do Contrato"
                  label-for="date"
                >
                  <flat-pickr
                    v-model="contrato.data"
                    class="form-control"
                    :config="config"
                    placeholder="YYYY-MM-DD"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                lg="3"
              >
                <b-form-group
                  label="Data Adjudicação"
                  label-for="date"
                >
                  <flat-pickr
                    v-model="contrato.dataAdjudicacao"
                    class="form-control"
                    :config="config"
                    placeholder="YYYY-MM-DD"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Estado"
                  label-for="h-estado"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Estado"
                    rules="required"
                  >
                    <v-select
                      v-model="estadoSelecionado.estado"
                      :options="estados.estado"
                      label="descricao"
                      title="codigo"
                      :reduce="estadoSelecionado => estadoSelecionado"
                      required
                    >
                      <template #option="option">
                        <span>{{ option.codigo }} - {{ option.descricao }}</span>
                      </template>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </v-select></validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Vendedor"
                  label-for="vendedor"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Vendedor"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        id="vendedor"
                        v-model="vendedor.nome"
                        type="text"
                        maxlength="3"
                      />
                      <b-input-group-prepend>
                        <b-button
                          variant="outline-secondary"
                          @click="showModalVendedor"
                        >
                          <feather-icon icon="SearchIcon" />
                        </b-button>
                      </b-input-group-prepend>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Valor Contrato"
                  label-for="h-valorContrato"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Valor Contrato"
                    rules="required"
                  >
                    <b-form-input
                      v-model="contrato.valorContrato"
                      type="number"
                      class="mb-2"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Moeda"
                  label-for="moeda"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Moeda"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        id="moeda"
                        v-model="moeda.descricao"
                        type="text"
                        maxlength="3"
                      />
                      <b-input-group-prepend>
                        <b-button
                          variant="outline-secondary"
                          @click="showModalMoeda"
                        >
                          <feather-icon icon="SearchIcon" />
                        </b-button>
                      </b-input-group-prepend>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="2"
            lg="2"
          >
            <b-col
              md="12"
            >
              <b-form-group
                label="Renovável"
                label-for="h-renovavel"
              >
                <b-form-checkbox
                  id="renovavel"
                  v-model="contrato.renovavel"
                  checked="contrato.renovavel"
                  name="renovavel"
                  switch
                  inline
                  :value="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Cancelado"
                label-for="h-cancelado"
              >
                <b-form-checkbox
                  id="cancelado"
                  v-model="contrato.cancelado"
                  checked="contrato.cancelado"
                  name="cancelado"
                  switch
                  inline
                  :value="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Processar Faturação"
                label-for="h-processaFaturacao"
              >
                <b-form-checkbox
                  id="processaFaturacao"
                  v-model="contrato.processaFaturacao"
                  checked="contrato.processaFaturacao"
                  name="processaFaturacao"
                  switch
                  inline
                  :value="true"
                />
              </b-form-group>
            </b-col>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      ref="modalVendedor"
      hide-footer
      size="xl"
    >
      <selecionarVendedor @vendedorSelecionado="selectVendedor" />
    </b-modal>

    <b-modal
      ref="modalMoeda"
      hide-footer
      size="xl"
    >
      <selecionarMoeda @moedaSelecionada="selectMoeda" />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BForm, BFormCheckbox, BFormInput, BButton, BInputGroupPrepend, BInputGroup, BModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import vSelect from 'vue-select'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import store from '@/store/index'
import selecionarMoeda from '../../Listagens/Moedas/SelecionarMoedas.vue'
import selecionarVendedor from '../../Listagens/Vendedores/SelecionarVendedores.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    selecionarMoeda,
    selecionarVendedor,
  },

  props: {
    contrato: Object,
    estados: Object,
    estadoSelecionado: Object,
    vendedor: Object,
    moeda: Object,

  },
  data() {
    return {
      config: {
        enableTime: false,
        altFormat: 'd-m-Y',
        altInput: true,
        dateFormat: 'Y-m-dTH:i:00',
        locale: Portuguese, // locale for this instance only
      },
      required,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getEstados()
    localize('pt', pt)
  },
  methods: {

    selectVendedor(row) {
      this.vendedor.id = row.id
      this.vendedor.codigo = row.codigo
      this.vendedor.nome = row.nome
      this.vendedor.email = row.email
      this.vendedor.telemovel = row.telemovel
      this.contrato.vendedor = row.id
      this.hideModalVendedor()
    },
    showModalVendedor() {
      this.$refs.modalVendedor.show()
    },
    hideModalVendedor() {
      this.$refs.modalVendedor.hide()
    },

    selectMoeda(row) {
      this.contrato.moeda = row.id
      this.moeda.id = row.id
      this.moeda.codigo = row.codigo
      this.moeda.descricao = row.descricao
      this.hideModalMoeda()
    },

    showModalMoeda() {
      this.$refs.modalMoeda.show()
    },
    hideModalMoeda() {
      this.$refs.modalMoeda.hide()
    },
    getEstados() {
      axios.get('/api/v1/estados/contratos')
        .then(res => {
          const resultado = res.data
          this.estados.estado.length = 0
          for (let i = 0; i < resultado.length; i += 1) {
            this.estados.estado.push(JSON.parse(JSON.stringify(resultado[i])))
          }
        })
      this.$forceUpdate()
    },
  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  .textoCentrado {
    text-align: center;
  }
  </style>
