<template>
  <b-card>
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <b-col
            md="3"
            lg="3"
          >
            <b-form-group
              label="Documento"
              label-for="tipoDoc"
            >
              <validation-provider
                #default="{ errors }"
                name="Documento"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="tipoDoc"
                    v-model="documentosContrato.descricao"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalDocumentosContrato"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Série"
              label-for="h-serie"
            >
              <validation-provider
                #default="{ errors }"
                name="Número"
                rules="required"
              >
                <v-select
                  v-model="serieSelecionada.serie"
                  :options="seriesContrato.series"
                  label="serie"
                  title="serie"
                  :reduce="serieSelecionada => serieSelecionada"
                  required
                  @input="alterarNumero(serieSelecionada.serie)"
                >
                  <template #option="option">
                    <span>{{ option.serie }} - {{ option.descricao }}</span>
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select></validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="2"
          >
            <b-form-group
              label="Número"
              label-for="h-numero"
              class="align-text-right"
            >

              <validation-provider
                #default="{ errors }"
                name="Número"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="btn-icon"
                      variant="outline-secondary"
                      @click="subtraiNumerador(contrato.numero)"
                    >
                      <feather-icon icon="ChevronLeftIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    id="numero"
                    v-model="contrato.numero"
                    class="textoCentrado"
                    type="number"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="btn-icon"
                      variant="outline-secondary"
                      @click="somaNumerador(contrato.numero)"
                    >
                      <feather-icon icon="ChevronRightIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Referência"
              label-for="h-referencia"
            >
              <div>
                <h4>
                  <span v-if="documentosContrato.codigo !='' && contrato.numero != '' && serieSelecionada.serie != ''">{{ documentosContrato.codigo }}/{{ serieSelecionada.serie.serie }}/{{ contrato.numero }}</span>
                  <span v-else>#</span>

                </h4>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="3"
            lg="3"
          >
            <b-form-group
              label="Entidade"
              label-for="entidade"
            >
              <validation-provider
                #default="{ errors }"
                name="Entidade"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="entidade"
                    v-model="cliente.cliente"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalClientes"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group
              label="Nome"
              label-for="h-nome"
            >
              <div>
                <h4>
                  <h4>
                    <span v-if="cliente.nome != ''">{{ cliente.nome }}</span>
                    <span v-else>Selecionar Cliente</span>

                  </h4>

                </h4>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Descrição"
              label-for="h-descricao"
            >
              <validation-provider
                #default="{ errors }"
                name="Descrição"
                rules="required"
              >
                <b-form-input
                  id="h-descricao"
                  v-model="contrato.descricao"
                  placeholder="Descrição"
                  maxlength="250"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

    <b-modal
      ref="modalDocumentosContrato"
      hide-footer
      size="xl"
    >
      <selecionarDocumentoContrato @documentosContratoSelecionado="selectDocumentosContrato" />
    </b-modal>
    <b-modal
      ref="modalClientes"
      hide-footer
      size="xl"
    >
      <selecionarCliente @clienteSelecionado="selectCliente" />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroupPrepend, BInputGroup, BModal,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import vSelect from 'vue-select'
import selecionarCliente from '../../Listagens/Clientes/SelecionarCliente.vue'
import selecionarDocumentoContrato from '../../Listagens/DocumentosContratos/SelecionarDocumentoContratos.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    vSelect,
    selecionarCliente,
    selecionarDocumentoContrato,
  },
  directives: {
    Ripple,
  },
  props: {
    cliente: Object,
    contrato: Object,
    documentosContrato: Object,
    seriesContrato: Object,
    serieSelecionada: Object,
  },

  data() {
    return {
      required,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    localize('pt', pt)
  },

  methods: {
    selectCliente(row) {
      this.cliente.cliente = row.cliente
      this.cliente.id = row.id
      this.cliente.nome = row.nome
      this.cliente.morada = row.morada
      this.cliente.codigoPostal = row.codigoPostal
      this.cliente.nif = row.nif
      this.cliente.idPais = row.idPais
      this.cliente.idMoeda = row.idMoeda
      this.cliente.idZona = row.idZona
      this.cliente.idVendedor = row.idVendedor
      this.cliente.telefone = row.telefone
      this.cliente.telemovel = row.telemovel
      this.cliente.email = row.email
      this.cliente.nomeFiscal = row.nomeFiscal
      this.cliente.localidade = row.localidade
      this.cliente.emailServicos = row.emailServicos
      this.cliente.ativo = row.ativo
      this.cliente.bloqueado = row.bloqueado
      this.cliente.observacoes = row.observacoes
      this.contrato.entidade = row.id
      this.hideModalClientes()
    },
    showModalClientes() {
      this.$refs.modalClientes.show()
    },
    hideModalClientes() {
      this.$refs.modalClientes.hide()
    },

    subtraiNumerador(numero) {
      this.contrato.numero = numero - 1
    },
    somaNumerador(numero) {
      this.contrato.numero = numero + 1
    },

    selectDocumentosContrato(row) {
      this.serieSelecionada.serie = []
      this.seriesContrato.series = []
      this.documentosContrato.id = row.id
      this.contrato.tipoDoc = row.id
      this.documentosContrato.codigo = row.codigo
      this.documentosContrato.descricao = row.descricao
      this.documentosContrato.inactivo = row.inactivo
      axios.get(`/api/v1/seriesContrato/documentosContrato/${this.documentosContrato.id}/complete/ativo`)
        .then(res => {
          const resultado = res.data
          this.seriesContrato.series.length = 0
          for (let i = 0; i < resultado.length; i += 1) {
            this.seriesContrato.series.push(JSON.parse(JSON.stringify(resultado[i])))
            if (this.seriesContrato.series[i].porDefeito === 1) {
              this.serieSelecionada.serie = this.seriesContrato.series[i]
            }
          }
          this.alterarNumero()
        })
      this.hideModalDocumentosContrato()
      this.$forceUpdate()
    },
    alterarNumero() {
      axios.get(`/api/v1/seriesContrato/${this.serieSelecionada.serie.id}/ultimoNumero`)
        .then(res => {
          this.contrato.numero = res.data.numerador + 1
        })
    },
    showModalDocumentosContrato() {
      this.$refs.modalDocumentosContrato.show()
    },
    hideModalDocumentosContrato() {
      this.$refs.modalDocumentosContrato.hide()
    },
  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .textoCentrado {
    text-align: center;
  }
  </style>
